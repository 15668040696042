/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { LayoutServiceContext, LayoutServiceData, SitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Route, Routes, useParams } from "react-router-dom";
import componentFactory from "~/temp/componentFactory";
import RouteHandler from "./RouteHandler";
import { SuitcaseStore } from "~/foundation/Suitcase/SuitcaseStore";
import { Provider as BalancerProvider } from "react-wrap-balancer";
import { ColorThemeProvider } from "~/foundation/Theme/ColorThemeContext";
import { ColorTheme, getDefaultColorTheme } from "~/foundation/Theme/color-themes/default-color-theme";
import { CustomContext } from "~/foundation/Jss/generated-types";
import { sitecoreContentConstants } from "~/foundation/SitecoreContent";
import { RouteData } from "~/foundation/Jss/useSitecoreContext";
import { PageDarkThemeBase } from "~/foundation/Theme/generated-types";
import { getDarkColorTheme } from "~/foundation/Theme/color-themes/dark-theme";
import { getLightColorTheme } from "~/foundation/Theme/color-themes/light-theme";
import { SlideInTagSearchProvider } from "~/foundation/Search/SlideInTagSearchContext";
import { AudioPlayerProvider } from "~/feature/Article/AudioPlayer/AudioPlayerProvider";

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
// export const routePatterns = [
// 	'/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
// 	'/:lang([a-z]{2})/:sitecoreRoute*',
// 	'/:sitecoreRoute*',
// ];

export function getColorTheme(context: LayoutServiceContext, route: RouteData<PageDarkThemeBase>): ColorTheme {
	let colorTheme: ColorTheme | null = null;
	const custom = context?.custom as CustomContext | undefined;

	if (route?.templateId === sitecoreContentConstants.templateIds.pageTypes.magazineArticleTemplateId) {
		colorTheme = route?.fields?.useDarkThemeAsDefault?.value === true // has to be === true
			? getDarkColorTheme(custom?.settings.isRtl || false)
			: getLightColorTheme(custom?.settings.isRtl || false);
	} else {
		colorTheme = getDefaultColorTheme(custom?.settings.isRtl || false);
	}

	return colorTheme;
}

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
const LANGUAGE_REG_EXP = /^\/?(([a-z]{2}-[a-zA-Z]{2})|([a-z]{2}))(\/|$)/g;

export const parseRouteParams = (url: string) => {
	const language = url.match(LANGUAGE_REG_EXP);
	const route = url.replace(LANGUAGE_REG_EXP, '');

	return {
		route: route.startsWith('/') ? route : `/${route}`,
		language: language ? language[0].replace(/\//g, '') : undefined,
	};
};

type JssRouteProps = {
	isSSR: boolean;
	siteName: string;
}

const JssRoute: FC<JssRouteProps> = (props) => {
	const params = useParams();
	const url = params['*'] ?? "/";

	return <RouteHandler {...parseRouteParams(url)} url={url} isSSR={props.isSSR} siteName={props.siteName} />;
};

type AppRootProps = {
	ssrState: LayoutServiceData | null;
	path: string;
	Router: any;
	siteName: string;
	routerContext: any;
};

// wrap the app with:
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
const AppRoot: FC<AppRootProps> = (props) => {
	const { path, Router, ssrState, siteName } = props;
	let colorTheme: ColorTheme;

	if (ssrState) {
		colorTheme = getColorTheme(ssrState.sitecore.context, ssrState.sitecore.route as RouteData<PageDarkThemeBase>);
	} else {
		colorTheme = getDefaultColorTheme(false)
	}

	return (
		<SitecoreContext componentFactory={componentFactory} layoutData={ssrState!}>
			<ColorThemeProvider colorTheme={colorTheme} componentName="AppRoot">
				<SuitcaseStore>
					<AudioPlayerProvider>
						<SlideInTagSearchProvider>
							<BalancerProvider>
								<Router location={path} context={{}}>
									<Routes>
										<Route path="*" element={<JssRoute isSSR={!!ssrState} siteName={siteName} />} />
									</Routes>
								</Router>
							</BalancerProvider>
						</SlideInTagSearchProvider>
					</AudioPlayerProvider>
				</SuitcaseStore>
			</ColorThemeProvider>
		</SitecoreContext>
	);
}

export default AppRoot;
