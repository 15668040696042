import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { SitecoreContextValue } from "~/foundation/Jss";
import { useEventEmitter } from "~/foundation/Events";
import { OneTrustEvent } from "./OneTrustEvent";


type OneTrustProps = {
	sitecoreContext: SitecoreContextValue
}

let isWaitingForOneTrust = true;

function waitForOneTrust(callback: () => void, timeout = 5000): void {
	if (!isWaitingForOneTrust) {
		callback();
		return;
	}

	const intervalTime = 100;
	let elapsedTime = 0;

	const interval = setInterval(() => {
		if (typeof window.OneTrust?.OnConsentChanged !== 'undefined') {
			clearInterval(interval);
			isWaitingForOneTrust = false;
			callback();
		} else if (elapsedTime >= timeout) {
			clearInterval(interval);
			isWaitingForOneTrust = false;
			console.error('OneTrust was not defined within the timeout period.');
		}

		elapsedTime += intervalTime;
	}, intervalTime);
}

export const OneTrust: FC<OneTrustProps> = ({ sitecoreContext }) => {
	const eventEmitter = useEventEmitter<OneTrustEvent>("OneTrust");
	const cookieConsentContext = sitecoreContext.custom.settings.cookieConsent;

	useEffect(() => {
		waitForOneTrust(() => {
			window.OneTrust!.OnConsentChanged((e: any) => { // eslint-disable-line
				eventEmitter.emit("onConsentChanged", e);
			})
		}, 5000);
	}, []);

	if (process.env.NODE_ENV === "development") {
		return null
	}

	return (
		<Helmet>
			{cookieConsentContext.oneTrustID !== "" &&
				<>
					<script
						src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
						type="text/javascript"
						data-domain-script={cookieConsentContext.oneTrustID}
						data-document-language="true"
						async
					/>
					<script
						type="text/javascript"
						dangerouslySetInnerHTML={{ __html: "function OptanonWrapper() { }" }}
					/>
				</>
			}
		</Helmet>
	)
}