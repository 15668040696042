import type { ChakraTheme } from "@chakra-ui/react";

export const sizes: ChakraTheme["sizes"] = {
	// base 1 = '4px'
	contentWrapperWidthMD: '74rem',//used on breakpoint md
	contentWrapperWidthLG: '80rem',//used on breakpoint lg
	contentWrapperWidthXL: '90rem',//used on breakpoint xl
	contentWrapperWidth2XL: '102rem',//used on breakpoint 2xl
	modulePY__SM: "3.5rem",
	modulePY__MD: "4.5rem",
	modulePY__XL: "6rem",
	gridGutter: '2.125rem',
	pageMarginSM: "1.5rem",
	pageGutter: '2rem',
	headerScrollBarHeight: ".1875rem",
	headerHeightBase: "5rem",
	headerHeightLG: "8.375rem",
	headerHeightXL: "10.4rem",
	breadCrumbHeight: "2.5rem",
	cardWidthMobile: "19.6rem",//"313px",
	cardWidth: "16rem",
	suitCaseWidth: "17.5rem",
	navigationTopSpacing: "6.125rem",
	782: '48.875rem',
	582: "36.375rem",
	482: "30.125rem",
	icons: {
		sm: "12px",
		md: "16px",
		lg: "24px",
		xl: "30px"
	},
};